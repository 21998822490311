<template>
  <div class="users main mr-0 pt-4">
    <users adminMode></users>
  </div>
</template>

<script>
import Users from '@/views/app/admin/management/Users.vue'
export default {
  components: {
    Users
  }
}
</script>

<style lang="scss" scoped></style>
